<template>
  <el-dialog
    :title="`${formatText(action)} Curation`"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-form :model="form" label-position="top">
      <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
        <el-col :md="24" class="">
          <eden-image-drag-and-drop
            :image-url.sync="form.banner_url"
            :image-text="'banner logo'"
            :image-folder="'curations'"
          />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Curation Name" prop="name">
            <el-input
              class=""
              v-model="form.name"
              type="text"
              placeholder="Curation Name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-form-item label="Business Category" prop="business_type_ids">
            <el-select
              v-model="form.business_type_ids"
              :loading="loading"
              multiple
            >
              <el-option
                v-for="item in businessType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item label="Start Date (Optional)" prop="start_date">
            <el-date-picker
              v-model="form.start_date"
              type="date"
              placeholder="Pick a day"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="End Date (Optional)" prop="end_date">
            <el-date-picker
              v-model="form.end_date"
              type="date"
              placeholder="Pick a day"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-form-item label="Description" prop="textarea">
            <el-input
              v-model="form.description"
              :rows="4"
              type="textarea"
              placeholder="Please input"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="loading"
        @click="createCuration"
        >Create Curation</el-button
      >
      <el-button v-else type="primary" :loading="updating" @click="update"
        >Save Curation</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import curation from "../../requests/curations/curation";
import * as actions from "@/store/action-types";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    curation: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        start_date: "",
        banner_url: "",
        end_date: "",
        description: "",
        name: "",
        business_type_ids: "",
      },
      businessType: [],
      loading: false,
      updating: false,
    };
  },

  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.form = this.curation;
        }
      }
    },
    curation: {
      immediate: true,
      handler(newValue) {
        if (this.action === "edit" && newValue.business_types) {
          this.form.business_type_ids = newValue.business_types.map(
            (type) => type.id,
          );
        }
      },
    },
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    businessTypes() {
      return this.$store.getters.business_types;
    },
  },
  created() {
    if (this.action === "edit") {
      this.form = { ...this.curation };
      this.form.business_type_ids = this.curation?.business_types?.map(
        (type) => type.id,
      );
    }
    this.getBusinessType();
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        start_date: "",
        end_date: "",
        description: "",
        name: "",
        business_type_ids: "",
      };
    },
    getBusinessType() {
      this.loading = true;
      this.$store.dispatch(actions.GET_BUSINESS_TYPE).then(() => {
        this.businessType = this.businessTypes;
        this.loading = false;
      });
    },

    createCuration() {
      this.loading = true;
      curation
        .create(this.form)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeModal();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    update() {
      console.log(this.form);
      this.updating = true;
      curation
        .update(this.curation.uuid, this.form)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeModal();
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    // getCurations() {
    //   this.loading = true;
    //   curation
    //     .list()
    //     .then((response) => {
    //       this.pageData = response.data.data.data
    //       this.from = this.pageData.from;
    //       this.to = this.pageData.to;
    //       this.total = this.pageData.total;
    //       this.pageSize = this.pageData.per_page;
    //       // this.setPageData();
    //       this.loading = false;
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       const errorMessage = error.response.data;
    //       if (errorMessage.errors) {
    //         const errorKeys = Object.keys(errorMessage.errors);
    //         this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
    //       } else {
    //         this.$message.error(errorMessage.message);
    //       }
    //     });
    // },
  },
};
</script>

<style scoped>
/* .upload-image {
  height: 112px !important;
  width: 100% !important;
} */
</style>
