<template>
  <div>
    <div v-if="loading">loading</div>
    <template v-else>
      <eden-page-header :title="'Curations'" :subtitle="curationName">
      </eden-page-header>
      <eden-table-actions :title="'Information'" :show-search="false">
        <template #actions>
          <div class="mr-2">
            {{
              status === "inactive" ? "Curation Inactive " : "Curation Active "
            }}
            <el-switch
              v-model="status"
              active-value="active"
              inactive-value="inactive"
              size="large"
              @change="handleToggle"
            />
          </div>
          <div>
            <el-dropdown
              v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
              :placement="'bottom'"
              @command="command"
            >
              <el-button type="plain">
                Actions
                <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{ action: 'edit' }"
                  icon="eden-icon--edit"
                  >Edit Curation</el-dropdown-item
                >
                <el-dropdown-item
                  class="text-danger"
                  :command="{ action: 'delete' }"
                  icon="eden-icon--eye"
                  >Delete Curation</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </eden-table-actions>
      <div class="is-flex gap-4 align-start mb-4 w-100 curation-table">
        <div class="info-img" v-if="filterCurations.banner_url">
          <img
            :style="{
              backgroundImage: `url(${filterCurations.banner_url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              height: '233px',
              width: '357px',
              borderRadius: '8px',
            }"
          />
        </div>
        <div
          class="info-card"
          :class="{ 'w-100': !filterCurations.banner_url }"
        >
          <curation-info-card
            :filter-curation="filterCurations"
            class="w-100"
          />
        </div>
      </div>
      <curation-products-table
        :filter-products="filterProducts"
        :filter-curation="filterCurations"
        @success="getCuration"
        @add-success="getCuration"
      />
    </template>
    <curation-delete
      :show.sync="showDeleteCuration"
      :curation-id="curationId"
      @success="$router.go(-1)"
    />
    <curations-form
      :action="curation.action"
      :show.sync="showEditModal"
      @success="getCuration"
      :curation="curation.data"
    />
  </div>
</template>

<script>
import CurationInfoCard from "@/components/Curations/CurationInfoCard.vue";
import CurationProductsTable from "@/components/Curations/CurationProductsTable.vue";
import CurationsForm from "@/components/Curations/CurationsForm.vue";
import CurationDelete from "@/components/Curations/CurationDelete.vue";
import curation from "../../requests/curations/curation";
export default {
  name: "CurationDetails",
  components: {
    CurationInfoCard,
    CurationProductsTable,
    CurationsForm,
    CurationDelete,
  },
  data() {
    return {
      filterProducts: [],
      filterCurations: {},
      showDeleteCuration: false,
      showEditModal: false,
      curation: {
        action: "add",
        visibility: false,
        data: {},
      },
      loading: false,
      status: "inactive",
    };
  },
  computed: {
    curationId() {
      return this.$route.params.id;
    },
    curationName() {
      return this.filterCurations.name;
    },
  },
  created() {
    this.getCuration();
  },
  methods: {
    command(command) {
      switch (command.action) {
        case "edit":
          this.curation.data = this.filterCurations;
          this.curation.action = "edit";
          this.showEditModal = true;
          break;
        case "delete":
          this.curation.data = this.filterCurations;
          this.showDeleteCuration = true;
          break;
      }
    },
    handleToggle() {
      console.log(this.status);
      let payload = {
        status: this.status === "active" ? "active" : "inactive",
      };
      curation
        .update(this.curationId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.shouldShow = false;
            this.getCuration();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
    getCuration() {
      this.loading = true;
      curation
        .curation(this.curationId)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.filterProducts = response.data.data.products;
            this.filterCurations = response.data.data.curation;
            this.status = response.data.data.curation.status;
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1400px) {
  .curation-table {
    flex-wrap: wrap;
  }
  .info-card {
    min-width: 100% !important;
  }
}

.info-card {
  min-width: 65%;
}
.info-img {
  min-width: 30%;
}
</style>
