<template>
  <eden-confirm-dialog title="Delete Curation?" button-type="danger" button-text="Delete Curation" :show.sync="shouldShow"
    @confirm="deleteCuration">
    <p>
      If you delete this curation, all data associated with it will be permanently erased, This action cannot be undone.
    </p>
  </eden-confirm-dialog>
</template>
  
<script>

import curation from '../../requests/curations/curation';

export default {
  name: "CurationDelete",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    curationId: String
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.deleting = false;
    },
    deleteCuration() {
      curation
        .delete(this.curationId)
        .then(response => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.curationId);
            if(response.data.message === "Curation not found") {
              alert("nnnn")
              this.$router.push({ name: "curations.index" });
            }
            this.shouldShow = false;
          }
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>
  
<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
  