<template>
    <div class="curation--summary">
        <eden-information-card>
            <template slot="content">
                <el-table :class="'general'" :data="info" :show-header="false" :span-method="arraySpanMethod">
                    <el-table-column>
                        <template slot-scope="scope">
                            <template>
                                <el-table :class="'inner-table'" :data="scope.row.data" :show-header="false">
                                    <el-table-column class="none">
                                        <template slot-scope="scope">
                                            <span class="label font-xsm">{{ scope.row.label }}</span>
                                            <p class="text font-sm">
                                                {{ scope.row.text }}
                                            </p>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </template>
                    </el-table-column></el-table>
            </template>
        </eden-information-card>
    </div>
</template>

<script>
export default {
    name: "CurationInfoCard",
    props: {
        filterCuration: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    data() {
        return {
        }
    },
    computed: {
        info() {
            return [
                {
                    data: [
                        {
                            label: "Name",
                            text: this.filterCuration.name ?? "-",
                        },
                        {
                            label: "No of products sold",
                            text: this.filterCuration.no_of_products_sold  ?? "-",
                        },
                        {
                            label: "Start Date",
                            text: this.formatDate(this.filterCuration.created_at, "ddd do, m y") ?? "--",
                        },
                    ],
                },
                {
                    data: [
                        {
                            label: "Business Type",
                            text: this.filterCuration.business_types?.map(type => type.name).join(', ') ?? "-",
                        },
                        {
                            label: "Value of products sold",
                            text: this.filterCuration.value_of_products_sold ?? "-",
                        },
                        {
                            label: "End Date",
                            text:  this.formatDate(this.filterCuration.end_date, "ddd do, m y") ?? "--",
                        },
                    ],
                },
                {
                    data: [
                        {
                            label: "Description",
                            text: this.filterCuration.description,
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        arraySpanMethod({ rowIndex }) {
            if (rowIndex === 2) {
                return [1, 3];
            } else if (rowIndex === 3) {
                return [3, 4];
            } else {
                return [1, 4];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
tr.el-table__row:last-child {
    border-left: 1px solid #EBEEF4 !important;
}

.information-card,
td.el-table_4_column_4.el-table__cell {
    padding: 0 !important;
}

td.el-table_4_column_4.el-table__cell {
    border-left: none !important;
}

.el-table__cell {
    padding: 0 !important;
}
</style>