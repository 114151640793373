<template>
  <div>
    <eden-table-actions
      placeholder="Search for a product"
      :title="title"
      @search="search"
      show-search
      v-if="filterProducts.length || searchQuery"
    >
      <template slot="actions">
        <el-button
          type="primary"
          class="mt-2 mx-auto"
          :loading="loading"
          @click="addProduct"
          >Add products</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <div v-else>
      <div class="eden-table-scroll">
        <el-table
          table-layout="auto"
          :data="filterProducts"
          v-if="filterProducts.length"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="45" />
          <el-table-column width="400">
            <template #header>
              <eden-table-column-header
                :label="'Name'"
                :property="'product_name'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'product_name')"
              />
            </template>
            <template v-slot="scope">
              <div class="is-flex is-align-center">
                <div class="eden-meal--image">
                  <img
                    v-if="scope.row.image_url || scope.row.image_url === ''"
                    :src="getThumbnailFromCloudinary(scope.row.image_url)"
                    class="meal--image"
                    alt="Image"
                    width="40px"
                    height="40px"
                  />
                </div>
                {{ scope.row.product_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <eden-table-column-header
                :label="'Price'"
                :property="'price'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'price')"
              />
            </template>
            <template v-slot="scope">
              {{ formatPrice(scope.row.price) }}
            </template>
          </el-table-column>

          <el-table-column>
            <template #header>
              <eden-table-column-header
                :label="'Name of Vendor'"
                :property="'vendor_name'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'vendor_name')"
              />
            </template>
            <template v-slot="scope">
              {{ scope.row.vendor_name }}
            </template>
          </el-table-column>

          <el-table-column>
            <template #header>
              <eden-table-column-header
                :label="'No of purchases'"
                :property="'no_of_purchases'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'no_of_purchases')"
              />
            </template>
            <template v-slot="scope">
              {{ scope.row.no_of_purchases }}
            </template>
          </el-table-column>

          <el-table-column>
            <template #header> </template>
            <template v-slot="scope">
              <el-button
                type="plain"
                class="text-danger"
                :loading="loading"
                @click="removeProduct(scope.row.product_uuid)"
                >Remove</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <eden-content-empty
          v-if="!filterProducts.length && searchQuery"
          :text="'No Products'"
        />
        <!-- <eden-pagination-vue  :current-page.sync="page" :total="Data.total" :page-size="Data.per_page"
                    :from="Data.from" :to="Data.to" /> -->
      </div>

      <div class="empty-content" v-if="!filterProducts.length && !searchQuery">
        <p>
          There are no products in this curation. <br />
          Tap the button below to add products to this curation.
        </p>

        <el-button
          type="primary"
          class="mt-2 mx-auto"
          :loading="loading"
          @click="addProduct"
          >Add products</el-button
        >
      </div>
    </div>
    <add-products
      :show.sync="showAddProduct"
      :filter-product="filterProducts"
      @success="$emit('add-success')"
      :business-type="filterCuration.business_types"
    />
  </div>
</template>

<script>
import curation from "../../requests/curations/curation";
import AddProducts from "@/components/Curations/AddProducts.vue";
export default {
  name: "CurationProductsTable",
  components: { AddProducts },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    filterProducts: {
      type: Array,
      default() {
        return [];
      },
    },
    filterCuration: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      searchQuery: "",
      page: 1,
      showAddProduct: false,
      sort: {
        property: "",
        direction: "asc",
      },
      multipleSelection: [],
      pagination: {
        from: null,
        to: null,
        total: null,
      },
    };
  },
  computed: {
    title() {
      return `${this.filterProducts.length} Products`;
    },
    curationId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedCustomers = val;
    },
    addProduct() {
      this.showAddProduct = true;
    },
    sortPageData(direction, property) {
      this.sort = { property, direction };
      this.filterProducts = this.sortList(
        this.filterProducts,
        property,
        direction,
      );
    },
    search(query) {
      this.searchQuery = query;
      this.loading = true;
      curation
        .search(this.curationId, this.searchQuery)
        .then((response) => {
          this.filterProducts = response.data.data.products;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    removeProduct(uuid) {
      let payload = {
        product_ids: [uuid],
      };
      curation
        .remove(this.curationId, payload)
        .then((response) => {
          if (response.data.status) {
            this.filterProducts = response.data.data.products;
            this.$message.success(response.data.message);
            this.$emit("success", this.curationId);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style>
.empty-content {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 125px;
}

.el-button--primary {
  margin-top: 15px !important;
}

.meal--image {
  margin-right: 12px;
  border-radius: 8px;
}
</style>
