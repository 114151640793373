var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_vm._v("loading")]):[_c('eden-page-header',{attrs:{"title":'Curations',"subtitle":_vm.curationName}}),_c('eden-table-actions',{attrs:{"title":'Information',"show-search":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.status === "inactive" ? "Curation Inactive " : "Curation Active ")+" "),_c('el-switch',{attrs:{"active-value":"active","inactive-value":"inactive","size":"large"},on:{"change":_vm.handleToggle},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',[(_vm.allowAccessFor(['superadmin', 'admin', 'operations']))?_c('el-dropdown',{attrs:{"placement":'bottom'},on:{"command":_vm.command}},[_c('el-button',{attrs:{"type":"plain"}},[_vm._v(" Actions "),_c('i',{staticClass:"eden-icon-arrow-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{ action: 'edit' },"icon":"eden-icon--edit"}},[_vm._v("Edit Curation")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{ action: 'delete' },"icon":"eden-icon--eye"}},[_vm._v("Delete Curation")])],1)],1):_vm._e()],1)]},proxy:true}])}),_c('div',{staticClass:"is-flex gap-4 align-start mb-4 w-100 curation-table"},[(_vm.filterCurations.banner_url)?_c('div',{staticClass:"info-img"},[_c('img',{style:({
            backgroundImage: `url(${_vm.filterCurations.banner_url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '233px',
            width: '357px',
            borderRadius: '8px',
          })})]):_vm._e(),_c('div',{staticClass:"info-card",class:{ 'w-100': !_vm.filterCurations.banner_url }},[_c('curation-info-card',{staticClass:"w-100",attrs:{"filter-curation":_vm.filterCurations}})],1)]),_c('curation-products-table',{attrs:{"filter-products":_vm.filterProducts,"filter-curation":_vm.filterCurations},on:{"success":_vm.getCuration,"add-success":_vm.getCuration}})],_c('curation-delete',{attrs:{"show":_vm.showDeleteCuration,"curation-id":_vm.curationId},on:{"update:show":function($event){_vm.showDeleteCuration=$event},"success":function($event){return _vm.$router.go(-1)}}}),_c('curations-form',{attrs:{"action":_vm.curation.action,"show":_vm.showEditModal,"curation":_vm.curation.data},on:{"update:show":function($event){_vm.showEditModal=$event},"success":_vm.getCuration}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }